.invalid-feedback {
    display: block !important;
}
.type-content {
    /*width: 3.888%;*/
    padding-right: 30px;
}
.tns-item {
    padding-right:30px;
}
.type-image-circle {
    padding: 0.15rem;
    background-color: #4AB;
    /*border: 1px solid var(--bs-border-color);*/
    /*border-radius: 0.375rem;*/
}
.types-center {
    display: flex;
    justify-content: center;
    overflow: auto;
    padding: 25px 0px;
    list-style: none;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
}
.bg-light-app {
    --bs-bg-opacity: 1 !important;
    background-color: #ffe2e7 !important;
}
.icons-left {
padding-left: 5px !important;
}

/*
custom calendar
*/
.react-calendar {
    width: 100% !important;
    border: 0px solid #a0a096 !important;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #00969d;
}
.react-calendar__tile--now {
    background: #ed8ca5 !important;
    border-radius: 10px;
    color: white !important;
}
.react-calendar__tile--active {
    background: #00969d !important;
    color: white !important;
    border-radius: 10px;
}
.react-calendar__tile {
    font-size: 1.1em !important;
}
.hour {
    padding: 1px;
}
.font-16 {
    font-size: 16px !important;
}


.pac-container {
    z-index: 100000 !important;
}

.payment-content {
    display: flex;
    justify-content: space-around;
}

.carousel-change {
    padding-bottom: 25px !important;
}
.carousel-container-with-scrollbar {
    padding-left: 100px;
    padding-bottom: 20px;
    overflow: visible !important;
}
.app-card {
    height: 90% !important;
}
.app-background {
    filter: drop-shadow(0 0 0 #F27FA2);
}

.card-footer {
    justify-content: space-between;
}
.icon-md-app {
    width: 2.6rem;
    height: 2.6rem;
    line-height: 2.6rem;
    text-align: center;
    font-size: 1.0rem;
}
.modal-custom {
    border:#00969d 1px solid !important;
}
.span-action {
    display: inline-flex;
    margin-left: 10px;
    background-color: #e40d0d;
    height: 25px !important;
    width: 25px !important;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}
.icon-prof {
    object-fit:fill;
    max-width: 100%;
    height: 100%;
}
.image-custom {
    height: 300px;
    height: fit-content;
}